<template>
  <div class="Wrap">
    <el-form
      v-if="!isNew"
      ref="oldForm"
      :rules="oldRules"
      :model="oldForm"
      label-width="80px"
    >
      <el-form-item
        label="手机号"
        key="account"
        prop="account"
        style="position: relative"
      >
        <el-input v-model="oldForm.account" disabled></el-input>
        <el-button
          class="sendCode"
          style="position: absolute; right: 3%"
          type="text"
          @click="sendCode"
          >{{ verificationText }}</el-button
        >
      </el-form-item>
      <!-- <el-form-item v-if="isShowCaptcha">
        <SmartCaptcha
          ref="captcha"
          scene="ic_login"
          width="100%"
          @success="smartCaptchaSuccess"
        />
      </el-form-item> -->
      <el-form-item label="验证码" prop="code" key="code">
        <el-input v-model="oldForm.code"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      v-if="isNew"
      ref="Form"
      :rules="rules"
      :model="form"
      label-width="80px"
    >
      <el-form-item
        label="新手机号"
        key="phone"
        prop="phone"
        style="position: relative"
      >
        <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
        <el-button
          class="sendCode"
          style="position: absolute; right: 3%"
          type="text"
          :disabled="!verificationflag1"
          @click="sendCode('1')"
          >{{ verificationText1 }}</el-button
        >
      </el-form-item>
      <!-- <el-form-item v-if="isShowCaptcha">
        <SmartCaptcha
          ref="captcha"
          scene="ic_login"
          width="100%"
          @success="smartCaptchaSuccess"
        />
      </el-form-item> -->
      <el-form-item label="验证码" prop="code" key="code">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button v-if="!isNew" type="primary" @click="submit('Form')"
        >下一步</el-button
      >
      <el-button v-if="isNew" type="primary" @click="submit('Form')"
        >提 交</el-button
      >
      <el-button @click="close">取 消</el-button>
    </div>
  </div>
</template>
<script>
import SmartCaptcha from "@/components/smartCaptcha/index.vue";
import { GetVerificationCode } from "@/api/common.js";
import { resetPhone, checkCode, getCode } from "@/api/system";
export default {
  components: { SmartCaptcha },
  props: {
    account: String,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
          this.verificationflag1 = true;
        } else {
          callback(new Error("请输入正确的手机号"));
          // this.isShowCaptcha = false;
        }
      } else {
        callback(new Error("请填写手机号码"));
        // this.isShowCaptcha = false;
      }
    };
    return {
      form: {
        phone: "",
        code: "",
        tokenId: "",
      },
      isNew: false,
      rules: {
        phone: [{ required: true, validator: validatePhone }],
        code: [
          { required: true, message: "短信验证码不能为空", trigger: "blur" },
          { min: 1, max: 6, message: "短信验证码格式错误", trigger: "blur" },
        ],
      },
      oldForm: {
        account: "",
        code: "",
      },
      oldRules: {
        code: [
          { required: true, message: "短信验证码不能为空", trigger: "blur" },
          { min: 1, max: 6, message: "短信验证码格式错误", trigger: "blur" },
        ],
      },
      isShowCaptcha: false,
      verificationflag: false,
      verificationText: "发送验证码",
      valiteCodeData: null,
      icCap: null,
      time: 60,
      timepiece: null,
      tokenId: "",
      verificationflag1: false,
      verificationText1: "发送验证码",
      time1: 60,
      timepiece1: null,
    };
  },
  // watch: {
  //   time(val) {
  //     if (val === 0) {
  //       this.resetTime();
  //     }
  //   },
  // },
  mounted() {
    this.oldForm.account = this.account;
  },
  methods: {
    submit() {
      const form = this.isNew ? this.$refs["Form"] : this.$refs["oldForm"];
      form.validate((valid) => {
        if (valid) {
          if (this.isNew) {
            resetPhone(this.form).then((res) => {
              if (res.code === 1) {
                this.$message.success("操作成功");
                this.close();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            checkCode(this.oldForm.code).then((res) => {
              if (res.code === 1) {
                this.tokenId = res.data;
                this.isNew = true;
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    sendCode(val) {
      getCode({
        mobile: this.isNew ? this.form.phone : this.oldForm.account,
        type: 4,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("验证码发送成功，请注意查收");
          if (val === "1") {
            this.verificationflag1 = false;
            // this.verificationText = this.time + "s后可重新发送";
            this.timepiece1 = setInterval(() => {
              if (this.time == 0) {
                clearInterval(this.timepiece1);
                this.verificationText1 = "重新发送";
                this.time1 = 60;
              }
              this.time1 -= 1;
              this.verificationText1 = this.time1 + "s后可重新发送";
            }, 1000);
          } else {
            this.verificationflag = false;
            // this.verificationText = this.time + "s后可重新发送";
            this.timepiece = setInterval(() => {
              if (this.time == 0) {
                clearInterval(this.timepiece);
                this.verificationText = "重新发送";
                this.time = 60;
              }
              this.time -= 1;
              this.verificationText = this.time + "s后可重新发送";
            }, 1000);
          }
        } else {
          this.$message.error(res.msg);
          this.resetTime();
        }
      });
    },
    changeTime() {
      // this.time -= 1;
      // this.verificationText = this.time + "s后可重新发送";
    },
    smartCaptchaSuccess(data) {
      this.valiteCodeData = data.data;
      this.icCap = data.control;
      this.verificationflag = false;
      // 验证成功 发生验证码
      const form = { mobile: this.form.phone, type: 3 };
      GetVerificationCode(form).then((res) => {
        if (res.code === 1 && res.data) {
          this.form.tokenId = res.data;
          // 发送成功
          this.$message.success("验证码发送成功，请注意查收");
          this.verificationflag = false;
          this.verificationText = this.time + "s后可重新发送";
          this.timepiece = setInterval(this.changeTime, 1000);
        } else {
          this.$message.error(res.msg);
          this.resetTime();
        }
      });
      this.isShowCaptcha = false;
    },
    resetTime() {
      clearInterval(this.timepiece);
      this.verificationText = "重新发送";
      this.time = 60;
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (reg.test(this.verificationForm.loginName)) {
        this.verificationflag = true;
      } else {
        this.verificationflag = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Wrap {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  .passwordTip {
    border: 1px solid #cedbef;
    padding: 10px 20px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
    }
    .first {
      color: #187ae8;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  .footer {
    text-align: center;
  }
}
</style>
